import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

import ROUTES from "~/constants/routes";

import AccountLayoutDiscoverPageSelector, {
  CONTENT_SECTION
} from "../AccountLayoutPageSelector";

const AccountLayoutIndexPageSelect = (): JSX.Element => (
  <Wrapper>
    <StyledScrollContainer vertical={false}>
      <AccountLayoutDiscoverPageSelector
        section={CONTENT_SECTION.posts}
        href={ROUTES.posts.index}
        hrefAlias={ROUTES.index}
      />
      <AccountLayoutDiscoverPageSelector
        section={CONTENT_SECTION.hypetv}
        href={ROUTES.hypetv.index}
      />
      {/* TODO: Uncover when will be implemented */}
      {/* <AccountLayoutDiscoverPageSelector
        section={CONTENT_SECTION.live}
        href={ROUTES.live.index}
        withNewMark
      /> */}
      <AccountLayoutDiscoverPageSelector
        section={CONTENT_SECTION.nft}
        href={ROUTES.nft.index}
        withNewMark
      />
    </StyledScrollContainer>
  </Wrapper>
);

export default AccountLayoutIndexPageSelect;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledScrollContainer = styled(ScrollContainer)`
  display: flex;

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;
