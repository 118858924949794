import styled from "styled-components";

import { FIXED_HEADER_SCROLL_JUMP_FIX_MIXIN } from "~/theme/adaptivity";

import { useAccountLayoutContext } from "../AccountLayoutProvider";
import AccountLayoutIndexPageSelect from "./AccountLayoutIndexPageSelect";

type HOCProps = {
  hideForUnauthorizedUser?: boolean;
};

type Props = {
  children: React.ReactNode;
};

const AccountLayoutIndexHOC = ({
  hideForUnauthorizedUser = false
}: HOCProps = {}) =>
  function AccountLayoutIndex({ children }: Props): JSX.Element {
    const { authorized, authorizationStatusFetched } =
      useAccountLayoutContext();
    const hideHeader =
      !authorizationStatusFetched || (hideForUnauthorizedUser && !authorized);

    return (
      <>
        {!hideHeader && (
          <Header>
            <InnerWrapper>
              <AccountLayoutIndexPageSelect />
            </InnerWrapper>
          </Header>
        )}
        {children}
      </>
    );
  };

export default AccountLayoutIndexHOC;

const Header = styled.header`
  background-color: ${({ theme }) => theme.newTheme.background.primaryInverse};
  overflow: hidden;
  position: fixed;
  top: ${({ theme }) => theme.layouts.account.headerHeight}px;
  z-index: ${({ theme }) => theme.zIndex.appBar};

  & + * {
    margin-top: 80px;
  }

  ${FIXED_HEADER_SCROLL_JUMP_FIX_MIXIN}
`;

const InnerWrapper = styled.div`
  padding: 20px;
`;
