import { NextPage, NextPageContext } from "next";

import { LayoutOverrideProps, PageWithLayout } from "~/declarations/next";

// prettier-ignore
const withLayoutOverride =
  // eslint-disable-next-line @typescript-eslint/ban-types
    <P extends object>({
      layout,
      subLayout,
      size,
      hideHeaderForUnauthorizedUser
    }: LayoutOverrideProps) =>
    (WrappedComponent: NextPage<P>): PageWithLayout<P> => {
      const Wrapper: NextPage<P> = (props: P): JSX.Element => {
        if (process.env.NODE_ENV !== "production") {
          const displayName =
            WrappedComponent.displayName ||
            WrappedComponent.name ||
            "Component";

          Wrapper.displayName = `withLayoutOverride(${displayName})`;
        }

        return <WrappedComponent {...props} />;
      };

      if (WrappedComponent.getInitialProps) {
        Wrapper.getInitialProps = async (ctx: NextPageContext): Promise<P> => {
          let componentProps = {} as P;

          componentProps =
            (await WrappedComponent.getInitialProps?.(ctx)) ?? ({} as P);

          return { ...componentProps };
        };
      }

      (Wrapper as PageWithLayout).size = size;
      (Wrapper as PageWithLayout).layout = layout;
      (Wrapper as PageWithLayout).subLayout = subLayout;
      (Wrapper as PageWithLayout).hideHeaderForUnauthorizedUser =
        hideHeaderForUnauthorizedUser;

      return Wrapper;
    };

export default withLayoutOverride;
