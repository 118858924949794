import NextLink from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import styled, { css, keyframes } from "styled-components";

import Button from "~/components/core/Button";
import Icon from "~/components/core/Icon";
import { omitStyleProps } from "~/utils/styled-components";

export interface Props {
  section: CONTENT_SECTION;
  href: string;
  hrefAlias?: string;
  disableRipple?: boolean;
  withNewMark?: boolean;
  withAttentionSeeker?: boolean;
  className?: string;
}

export enum CONTENT_SECTION {
  posts,
  hypetv,
  live,
  nft
}

export const SELECTOR_PARAMS_BY_SECTION = {
  [CONTENT_SECTION.posts]: {
    Icon: <Icon variant="posts" />,
    IconSelected: <Icon variant="postsFilled" />,
    textKey: "posts_name"
  },
  [CONTENT_SECTION.hypetv]: {
    Icon: <Icon variant="hypeTV" />,
    IconSelected: <Icon variant="hypeTVFilled" />,
    textKey: "hypetv_name"
  },
  [CONTENT_SECTION.live]: {
    Icon: <Icon variant="live" />,
    IconSelected: <Icon variant="liveFilled" />,
    textKey: "live_name"
  },
  [CONTENT_SECTION.nft]: {
    Icon: <Icon variant="nft" />,
    IconSelected: <Icon variant="nftFilled" />,
    textKey: "nft_name"
  }
};

const AccountLayoutPageSelector = ({
  href,
  hrefAlias,
  section,
  disableRipple,
  className,
  withNewMark,
  withAttentionSeeker
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { textKey, Icon, IconSelected } = SELECTOR_PARAMS_BY_SECTION[section];
  const pathname = router.pathname;
  const active = pathname === href || pathname === hrefAlias;
  const withAttention = withAttentionSeeker && !active;

  return (
    <NextLink href={href} passHref>
      <StyledButton
        disableRipple={disableRipple}
        disableTouchRipple={disableRipple}
        className={className}
        startIcon={active ? IconSelected : Icon}
        endIcon={withNewMark ? <NewMark>{t("new")}</NewMark> : null}
        variant={withAttention ? "colorful" : "outlined"}
        active={active}
        withAttention={withAttention}
      >
        {t(textKey)}
      </StyledButton>
    </NextLink>
  );
};

export default AccountLayoutPageSelector;

const gradientTransfusion = keyframes`
  {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const StyledButton = styled(Button).withConfig<{
  active: boolean;
  withAttention?: boolean;
}>(omitStyleProps(["active", "withAttention"]))`
  && {
    border-color: ${({ active, theme }) =>
      active
        ? theme.newTheme.background.primary
        : theme.newTheme.transparent[16]};
    flex-shrink: 0;
    font-size: 13px;

    .MuiButton-label {
      padding: 7px 25px;
    }

    .MuiButton-endIcon {
      width: 32px;
      height: auto;
      z-index: 1;
    }

    svg {
      // TODO: Check colors
      color: ${({ active, theme }) =>
        active
          ? theme.newTheme.background.primary
          : theme.newTheme.background.quaternary};
    }

    ${({ withAttention }) =>
      withAttention &&
      css`
        animation: ${gradientTransfusion} 3s ease alternate infinite;
        background-size: 300% 300%;
        border-color: transparent;

        &:hover {
          .MuiButton-label {
            background: ${({ theme }) => theme.newTheme.background.octonary};
            color: ${({ theme }) => theme.newTheme.text.primary};
          }
        }
      `}
`;

const NewMark = styled.span`
  && {
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.newTheme.background.primary};
    border: solid 2px transparent;
    border-radius: 8px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.newTheme.text.primaryInverse};
    font-size: 13px;
    line-height: 20px;
    margin: auto;
    margin-right: 12px;
    padding: 0 6px;
    position: relative;

    &:after {
      background: ${({ theme }) => theme.newTheme.gradient.primary};
      border-radius: inherit;
      bottom: -2px;
      content: "";
      left: -2px;
      position: absolute;
      right: -2px;
      top: -2px;
      z-index: -1;
    }
  }
`;
